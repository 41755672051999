"use client";
import Link from "next/link";
import React from "react";

import Image from "next/image";
import { motion } from "framer-motion";
import { useTranslations } from "next-intl";

interface ProductCardType {
  title: string;
  description: string;
  image: string;
  link: string;
}

export default function Products() {
  const t = useTranslations("Homepage.Product");
  const tProduct = useTranslations("Homepage.Product.productItems");

  // useTranslations dan content array of json harus didalam component, (TIDAK BOLEH DILUAR COMPONENT)
  const products: ProductCardType[] = [
    {
      title: tProduct("PendanaanBudidayaUpgradeKandang.title"),
      description: tProduct("PendanaanBudidayaUpgradeKandang.description"),
      image: "/assets/products/pendanaan.webp ",
      link: "/pendanaan-perbaikan-kandang",
    },
    {
      title: tProduct("SmartFarmingTechnology.title"),
      description: tProduct("SmartFarmingTechnology.description"),
      image: "/assets/products/smartfarm-technology.webp",
      link: "/teknologi/iot",
    },
    {
      title: tProduct("AyamTelur.title"),
      description: tProduct("AyamTelur.description"),
      image: "/assets/products/ayam-telur.webp ",
      link: "/karkas-dan-telur",
    },
    {
      title: tProduct("Sapronak.title"),
      description: tProduct("Sapronak.description"),
      image: "/assets/products/sapronak.webp ",
      link: "/sapronak",
    },
  ];
  return (
    <section
      className="mb-10 text-[#101828] text-xs px-6 max-w-[1280px] mx-auto"
      id="products"
    >
      {/*  */}

      <div
        className=" bg-[#0C111D] rounded-3xl py-8 px-4 lg:p-10"
        style={{
          backgroundImage: `url('/assets/bg-vector.png')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          viewport={{ once: true, amount: 0.3 }}
          whileInView={{ opacity: 1 }}
          className="text-center text-white lg:p-10 lg:w-[80%] mx-auto "
        >
          <h1 className="text-xl md:text-3xl font-medium w-full mx-auto lg:max-w-[700px] ">
            {t.rich("title", {
              span: (chunks) => (
                <span className="text-[#F5BE01]">{chunks}</span>
              ),
            })}
          </h1>
          <p className="mt-6 text-base md:text-lg mx-auto text-[#D0D5DD] lg:max-w-[550px] md:line-clamp-2 mb-6">
            {t("subtitle")}
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 200 }}
          viewport={{ once: true, amount: 0.25 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, type: "spring", stiffness: 120 }}
          id="product-solution-grid"
          className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:grid-rows-2 lg:max-w-[80%] mx-auto text-white pb-10"
        >
          {/* CARD */}
          {products.map((t, index) => (
            <motion.div
              key={t.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }} // Staggered delay based on index
              style={{ width: "100%" }}
            >
              <ProductCard
                title={t.title}
                description={t.description}
                link={t.link}
                image={t.image}
              />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
}

const ProductCard: React.FC<ProductCardType> = ({
  description,
  image,
  link,
  title,
}) => {
  const t = useTranslations("Homepage.Product");

  return (
    <div className=" bg-[#1E222C]  rounded-lg shadow mx-auto max-w-[480px]">
      <div className="p-5">
        <Link href={link}>
          <h5 className="mt-2 mb-2 text-xl font-bold tracking-tight text-[#F5BE01] line-clamp-2">
            {title}
          </h5>
        </Link>
        <p className="mt-4 text-sm lg:line-clamp-7 lg:h-[120px] font-normal">
          {description}
        </p>
        <Link
          href={link}
          className="text-sm inline-flex items-center gap-2 font-medium text-center mt-6 mb-4"
        >
          {t("btnLihatSelengkapnya")}
          <svg
            width="16"
            height="16"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_3946_630)">
              <g clipPath="url(#clip1_3946_630)">
                <path
                  d="M3.61491 6.91667C5.05578 4.42583 7.74888 2.75 10.8334 2.75C15.4358 2.75 19.1667 6.48096 19.1667 11.0833C19.1667 15.6857 15.4358 19.4167 10.8334 19.4167C7.74888 19.4167 5.05578 17.7408 3.61491 15.25M10.8333 14.4167L14.1667 11.0833M14.1667 11.0833L10.8333 7.75M14.1667 11.0833H2.5"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_3946_630">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(0.5 0.75)"
                />
              </clipPath>
              <clipPath id="clip1_3946_630">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(0.5 0.75)"
                />
              </clipPath>
            </defs>
          </svg>
        </Link>
        <Link href={link}>
          <img className="rounded-lg" src={image} alt={title} />
        </Link>
      </div>
    </div>
  );
};
