"use client";
import { Button } from "@/components/ui/button";
import Link from "next/link";
import Image from "next/image";
import { motion } from "framer-motion";
import { useTranslations } from "next-intl";

export default function BannersCTA(props?: { href?: string }) {
  const t = useTranslations("Homepage.CTA");

  return (
    <motion.section
      initial={{ opacity: 0, y: 100 }}
      viewport={{ once: true }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, type: "spring" }}
      className="my-10 bx-container text-[#101828] text-xs"
      id="cta"
    >
      {/* OPTIMAL */}
      <div
        className="flex w-full lg:h-[480px] justify-center items-center rounded-xl mb-10 relative"
        style={{
          backgroundImage: `url('/assets/cta-new-bg.webp')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="w-full h-full relative  rounded-xl flex flex-wrap justify-end lg:justify-between lg:items-center">
          {/* COPY CTA WITH BTN */}
          <div className="flex-col justify-center items-center  lg:ml-10 relative z-10 p-10">
            <h1 className="text-[#101828] text-3xl md:text-4xl font-extrabold mb-4 leading-10 md:max-w-[550px]">
              {t.rich("title", {
                span: (chunks) => <span className="block mt-3">{chunks}</span>,
              })}
            </h1>
            <p className="text-black font-bold text-lg lg:w-full md:max-w-[80%]">
              {t("subtitle")}
            </p>
            <Link
              target="_blank"
              href={
                props?.href ??
                "https://api.whatsapp.com/send/?phone=628112648133&text&type=phone_number&app_absent=0 "
              }
            >
              <Button className="bg-gradient-to-b from-[#000000] to-[#333333] btn-comfortable text-white mt-10">
                {t("btnGabungKemitraan")}
              </Button>
            </Link>
          </div>
          {/*  */}

          <div className="lg:absolute bottom-0 right-0 h-full mr-10">
            <Image
              className="w-full h-full object-cover"
              src="/assets/cta-new-content.webp"
              alt="cta"
              width={500}
              height={500}
            />
          </div>
        </div>
      </div>
      {/* END OPTIMAL */}
    </motion.section>
  );
}
